import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GraphIcon from '@mui/icons-material/BarChart';
import CartIcon from '@mui/icons-material/ShoppingCart';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import SignOutIcon from '@mui/icons-material/ExitToApp';
import BagIcon from '@mui/icons-material/ShoppingBag';
import Dlogo from '../assets/DLogo.png';
import './component.css';
import { Link, useLocation } from 'react-router-dom';
import PostIcon from '@mui/icons-material/PostAdd';

const drawerWidth = 240;

function ResponsiveDrawer() {
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [userMode, setUserMode] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Fetch user mode from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { mode } = JSON.parse(storedUser);
      setUserMode(mode);
    }
  }, []);

  const handleItemClick = (text) => {
    setActiveItem(text);
  };

  const handleSignOut = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <Drawer
      className='d-drawer'
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Divider />
      <img className='dashboard-logo' src={Dlogo} alt="Logo" />
      <List>
        {[
          { text: 'Dashboard', icon: <GraphIcon />, mode: 'Posting' },
          { text: 'Find-Work', icon: <BagIcon />, mode: 'Bidding' },
          // { text: 'Order', icon: <CartIcon />, mode: '' },
          { text: 'Messages', icon: <MessageIcon />, mode: '' },
          { text: 'Settings', icon: <SettingsIcon />, mode: '' },
          { text: 'Sign out', icon: <SignOutIcon />, mode: '' },
          // New item for posting jobs
          { text: 'Job-Post', icon: <PostIcon />, mode: 'Posting' },
        ].map(({ text, icon, mode }) => {
          const isJobPost = text === 'Job-Post' && mode === 'Posting';
          const shouldDisplay = (userMode === 'Posting' && text === 'Find-Work') ||
                                (userMode === 'Bidding' && text === 'Dashboard') ||
                                (userMode === 'Bidding' && text === 'Job-Post');
          return (
            <ListItem
              key={text}
              disablePadding
              className={isJobPost ? 'job-post-item' : ''}
              sx={{
                width: '200px',
                display: shouldDisplay ? 'none' : 'block',
                '&:hover': {
                  backgroundColor: 'black',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                  '& .MuiListItemText-primary': {
                    color: '#FFFFFF',
                  },
                },
                '&.Mui-selected': {
                  backgroundColor: '#000000',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                  '& .MuiListItemText-primary': {
                    color: '#FFFFFF',
                  },
                },
                marginLeft: '10px',
                marginRight: '10px',
                borderRadius: '15px',
                marginBottom: '5px',
              }}
            >
              {text === 'Sign out' ? (
                <ListItemButton
                  onClick={handleSignOut}
                  sx={{
                    color: '#000000',
                    '& .MuiListItemIcon-root': {
                      color: '#000000',
                    },
                  }}
                >
                  <ListItemIcon>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              ) : (
                <ListItemButton
                  component={Link}
                  to={`/${text.toLowerCase()}`}
                  onClick={() => handleItemClick(text)}
                  selected={activeItem === text}
                  sx={{
                    color: activeItem === text ? '#FFFFFF' : '#000000',
                    '& .MuiListItemIcon-root': {
                      color: activeItem === text ? '#FFFFFF' : '#000000',
                    },
                  }}
                >
                  <ListItemIcon>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              )}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}

export default ResponsiveDrawer;
