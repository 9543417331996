import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Splash from '../src/pages/splash/index'
import Login from './pages/authentication/login';
import Register from './pages/authentication/register';
import Selection from './pages/authentication/selection';
import Dashboard from './dashboard/Posting/dashboard';
import JobPostForm from './dashboard/Posting/JobPostForm';
import Chat from './dashboard/Posting/Chat';
import FindWork from './dashboard/Bidding/FindWork';
import JobDetail from './dashboard/Bidding/JobDetail';

import PrivateRoute from './PrivateRoute';
import LiveAuctions from './dashboard/Posting/LiveAuctions';
import LiveAuctionsDetail from './dashboard/Posting/LiveAuctionsDetail';


function App() {

  const auth=localStorage.getItem('user')

  return (<>
    <BrowserRouter>
    <Routes>
        <Route exact path="/" element={<Splash/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/register" element={<Register/>}/>

        <Route element={<PrivateRoute/>}>

        <Route exact path="/mode" element={<Selection/>}/>
        <Route exact path="/dashboard" element={<Dashboard/>}/>
        <Route exact path="/job-post" element={<JobPostForm/>}/>
        <Route exact path="/messages" element={<Chat/>}/>
        <Route exact path="/find-work" element={<FindWork/>}/>
        <Route exact path="/job-detail/:id" element={<JobDetail/>}/>
        <Route exact path="/live-auctions" element={<LiveAuctions/>}/>
        <Route exact path="/live-auctions/:id" element={<LiveAuctionsDetail/>}/>

        </Route>

        </Routes>
        </BrowserRouter>
    
    </>
  );
}

export default App;
