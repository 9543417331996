import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import './posting.css'
import { Link } from 'react-router-dom';

const ProgressCard = () => {
  return (
    <>
    <Grid container justifyContent="center" alignItems="center" sx={{ pb:5 }}>
    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link className='cards-link' to={'/live-auctions'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    <Card className='progress-circular-card ca-1' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

      <CardContent>

        <h5 className='progress-circular-heading'>
          Live Auctions
          </h5>
      </CardContent>

    </Card>
    </Link>

        </Grid>
    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link className='cards-link' to={'/live-auctions'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    <Card className='progress-circular-card ca-2' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CardContent>
        <h5 className='progress-circular-heading'>
          Won Auctions
          </h5>
        
      </CardContent>
    </Card>
    </Link>
    </Grid>
    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Link className='cards-link' to={'/live-auctions'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    <Card className='progress-circular-card ca-3' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CardContent>
        <h5 className='progress-circular-heading'>
          Finished
          </h5>
        
      </CardContent>
    </Card>
    </Link>
    </Grid>
 </Grid>
   
    </>
  );
};

export default ProgressCard;
