import React, { useState, useEffect } from 'react';
import '../../App.css';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Logo from '../../assets/logob.png';
import Userf from '../../assets/userf.png';
import axios from 'axios'; // Import axios for making HTTP requests

const Selection = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [userId, setUserId] = useState(null); // State to store user ID

    useEffect(() => {
        // Fetch user ID from localStorage
        const storedUserId = localStorage.getItem('userId');
        setUserId(storedUserId);
    }, []);

    const handleModeSelection = async (mode) => {
        try {
            // Send a request to update user mode with user ID included in the payload
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/user/mode`, { userId, mode });
            console.log(response.data);
            // Redirect to dashboard or any other page after mode selection
            navigate('/dashboard');
        } catch (error) {
            console.error('Error selecting mode:', error);
            setError('Failed to update mode. Please try again later.');
        }
    };

    return (
        <div className="login-bg">
            <Grid container justifyContent="center" alignItems="center" sx={{ pb: 5 }}>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
            </Grid>

            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img className='login-svg' src={Userf} style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', pl: { xs: 4 }, pr: { xs: 4 }, pt: { xs: 4 }, pb: { xs: 4 } }}>
                    <Card sx={{ backgroundColor: '#ffffff', width: 550, px: 3, py: 4 }} className='login-card'>
                        <CardContent>
                            <h4> What do you want to do</h4>
                            {/* Handle mode selection */}
                            <Button onClick={() => handleModeSelection('Posting')} variant="contained" size="large" className="login-btn">Posting</Button>
                            <Button onClick={() => handleModeSelection('Bidding')} variant="contained" size="large" className="login-btn">Bidding</Button>
                            {error && <p>{error}</p>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default Selection;
