import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import { Button, TextField, Select, MenuItem, FormControl } from '@mui/material';
import './posting.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios'; // Import Axios
import { Link } from 'react-router-dom';

const JobPostForm = () => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    briefDescription: '',
    dateOfEntry: '',
    portOfEntry: '',
    merchandise: '',
    transportMethod: '',
    timeFrame: '', // Added timeframe field
    lengthLimit: '', // New field
    weightLimit: '', // New field
    workExperience: '', // New field
    additionalEquipment: '', // New field
    location: '', // New field
    budget: '', // New field
    typeOfWork: '',
    title: '' // New field
  });

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      handleOpenModal(); // Open the modal when reaching the second step
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setStep(2); // Reset the step to 2 after closing the modal
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/job-post`, formData);
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Header />
        <br />

        <div>
          {step === 1 && (
            <>
              {/* Step 1: File uploading area fields */}
              <Grid container justifyContent="flex-start" alignItems="center" sx={{ pb: 5, pl: 5, pr: 5 }}>
                <Grid item xs={12} sm={12}>
                  <h1 className='form-h1'>Upload Invoices</h1>
                  <div className='upload-solid'>
                    <div className='upload-dotted' style={{ textAlign: 'center' }}>
                      <input
                        type="file"
                        multiple
                        onChange={() => {}} // Ignore file change for now
                        style={{ display: 'none' }}
                        id="file-input"
                      />
                      <label htmlFor="file-input">
                        <Button
                          variant="contained"
                          component="span"
                          style={{ backgroundColor: 'black', color: 'white', borderRadius: '25px', marginRight: '10px' }}
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Files
                        </Button>
                      </label>
                      <br />
                      <br />
                      <span>Click here to upload or drop media here</span>
                    </div>
                  </div>
                  
                </Grid>
              </Grid>
            </>
          )}
          {step === 2 && (
            <>
              {/* Step 2: Input fields */}
              <br />
              <br />
              <Grid container justifyContent="flex-start" alignItems="center" sx={{ pb: 5, pl: 5, pr: 5 }}>
                <Grid item xs={12} sm={12}>
                <TextField
                    label="Job Title"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Brief Description"
                    multiline
                    fullWidth
                    rows={10}
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="briefDescription"
                    value={formData.briefDescription}
                    onChange={handleChange}
                  />
                  <TextField
                    // label="Date of Entry"
                    type="date"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="dateOfEntry"
                    value={formData.dateOfEntry}
                    onChange={handleChange}
                  />
                  {/* <TextField
                    label="Port of Entry"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="portOfEntry"
                    value={formData.portOfEntry}
                    onChange={handleChange}
                  /> */}

<FormControl fullWidth variant="outlined" style={{ marginBottom: '20px', borderRadius: '15px' }}>
      <label id="additionalEquipment-label">Port of Entry</label>
      <Select
        labelId="portOfEntry-label"
        label="Port of Entry"
        name="portOfEntry"
        value={formData.portOfEntry}
        onChange={handleChange}
      >
        <MenuItem value="Houston, Tx">Houston, Tx</MenuItem>
        <MenuItem value="Long Beach, Ca">Long Beach, Ca</MenuItem>
        <MenuItem value="Los Angeles, Ca">Los Angeles, Ca</MenuItem>
        <MenuItem value="Laredo, Tx">Laredo, Tx</MenuItem>
        <MenuItem value="Nogales, Az">Nogales, Az</MenuItem>
        <MenuItem value="New York, Ny">New York, Ny</MenuItem>
        <MenuItem value="Miami, Fl">Miami, Fl</MenuItem>

      </Select>
    </FormControl>



                  <TextField
                    label="Commodity"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="merchandise"
                    value={formData.merchandise}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Transport Method"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '20px', borderRadius: '15px' }}
                    name="transportMethod"
                    value={formData.transportMethod}
                    onChange={handleChange}
                  />
      
    {/* New fields */}
    {/* <TextField
      label="Length Limit"
      fullWidth
      variant="outlined"
      style={{ marginBottom: '20px', borderRadius: '15px' }}
      name="lengthLimit"
      value={formData.lengthLimit}
      onChange={handleChange}
    />
    <TextField
      label="Weight Limit"
      fullWidth
      variant="outlined"
      style={{ marginBottom: '20px', borderRadius: '15px' }}
      name="weightLimit"
      value={formData.weightLimit}
      onChange={handleChange}
    />
    <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px', borderRadius: '15px' }}>
      <label id="workExperience-label">Work Experience</label>
      <Select
        labelId="workExperience-label"
        id="workExperience"
        label="Work Experience"
        name="workExperience"
        value={formData.workExperience}
        onChange={handleChange}
      >
        <MenuItem value="internship">Internship</MenuItem>
        <MenuItem value="workRemotely">Work Remotely</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px', borderRadius: '15px' }}>
      <label id="additionalEquipment-label">Additional Equipment</label>
      <Select
        labelId="additionalEquipment-label"
        id="additionalEquipment"
        label="Additional Equipment"
        name="additionalEquipment"
        value={formData.additionalEquipment}
        onChange={handleChange}
      >
        <MenuItem value="any">Any</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl> */}
    <TextField
      label="Location"
      fullWidth
      variant="outlined"
      style={{ marginBottom: '20px', borderRadius: '15px' }}
      name="location"
      value={formData.location}
      onChange={handleChange}
    />
    {/* <TextField
      label="Estimated Value"
      fullWidth
      variant="outlined"
      style={{ marginBottom: '20px', borderRadius: '15px' }}
      name="budget"
      value={formData.budget}
      onChange={handleChange}
    /> */}
    <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px', borderRadius: '15px' }}>
      <label id="typeOfWork-label">Partner Government Agencies</label>
      <Select
        labelId="typeOfWork-label"
        id="typeOfWork"
        label="Type of Work"
        name="typeOfWork"
        value={formData.typeOfWork}
        onChange={handleChange}
      >
        <MenuItem value="Department of Agriculture (USDA)">Department of Agriculture (USDA)</MenuItem>
        <MenuItem value="Bureau of Alcohol, Tobacco, and Firearms (ATF)">Bureau of Alcohol, Tobacco, and Firearms (ATF)</MenuItem>
        <MenuItem value="Federal Drug Administration (FDA)">Federal Drug Administration (FDA)</MenuItem>
        <MenuItem value="Office of Textiles and Apparel">Office of Textiles and Apparel</MenuItem>
        <MenuItem value="Environmental Protection Agency (EPA)">Environmental Protection Agency (EPA)</MenuItem>

      </Select>
    </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          {/* Modal with dropdown and buttons */}
          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center', // Center align the content
                bgcolor: 'white',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                }}
              >
                <CloseIcon />
              </IconButton>

              <FormControl fullWidth sx={{ mb: 2 }}> {/* Add margin bottom */}
                <label>Set Time Frame:</label>
                <Select
                  labelId="time-frame-label"
                  id="time-frame"
                  label="Time Frame"
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: '20px', borderRadius: '15px' }}
                  name="timeFrame"
                  value={formData.timeFrame}
                  onChange={handleChange}
                >
                  <MenuItem value="12 hrs">12 hrs</MenuItem>
                  <MenuItem value="24 hrs">24 hrs</MenuItem>
                  <MenuItem value="48 hrs">48 hrs</MenuItem>

                  {/* Add more time frames as needed */}
                </Select>
              </FormControl>

              <Link to={'/dashboard'}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ backgroundColor: 'black', color: 'white', borderRadius: '15px', width: '100%', mb: 1 }} // Full width and margin bottom
                >
                  Submit
                </Button>
              </Link>

              <Link to={'/dashboard'}>
                <Button
                  variant="contained"
                  onClick={handleCloseModal}
                  color="error" // Danger color
                  sx={{ color: 'white', borderRadius: '15px', width: '100%', mb: 1 }} // Full width and margin bottom
                >
                  No
                </Button>
              </Link>
            </Box>
          </Modal>
        </div>
        <div>
          <Grid container justifyContent="flex-end" alignItems="center" sx={{ pb: 5, pl: 5, pr: 5 }}>
            {step > 1 && (
              <Button onClick={handleBack} style={{ backgroundColor: 'black', color: 'white', borderRadius: '15px', marginRight: '10px' }}>
                <KeyboardArrowLeftIcon />
                Back
              </Button>
            )}
            <Button onClick={handleNext} style={{ backgroundColor: 'black', color: 'white', borderRadius: '15px' }}>
              {step === 3 ? <CheckIcon /> : <KeyboardArrowRightIcon />}
              {step === 3 ? 'Finish' : 'Next'}
            </Button>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default JobPostForm;
