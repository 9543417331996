  import React, { useState, useEffect } from 'react';
  import Modal from '@mui/material/Modal';
  import Box from '@mui/material/Box';
  import IconButton from '@mui/material/IconButton';
  import CloseIcon from '@mui/icons-material/Close';
  import Sidebar from '../../components/sidebar';
  import Header from '../../components/header';
  import ProgressCard from '../Posting/FourCards';
  import MyTable from '../Posting/Tables';
  import { Button, Card, CardContent, Typography, Grid, TextField } from '@mui/material';
  import { Link } from 'react-router-dom';
  import FilterListIcon from '@mui/icons-material/FilterList';
  import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
  import LocationOnIcon from '@mui/icons-material/LocationOn';
  import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
  import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
  import EventIcon from '@mui/icons-material/Event';
  import Drawer from '@mui/material/Drawer';
  import FormControl from '@mui/material/FormControl';
  import FormLabel from '@mui/material/FormLabel';
  import RadioGroup from '@mui/material/RadioGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import Radio from '@mui/material/Radio';
  import InputLabel from '@mui/material/InputLabel';
  import Select from '@mui/material/Select';
  import MenuItem from '@mui/material/MenuItem';
  import Slider from '@mui/material/Slider';
  
  const FindWork = () => {
      const [open, setOpen] = useState(false);
      const [drawerOpen, setDrawerOpen] = useState(false);
      const [jobs, setJobs] = useState([]); // State to store job data
      const [lengthLimitOptions, setLengthLimitOptions] = useState([]); // State to store length limit options
      const [weightLimitOptions, setWeightLimitOptions] = useState([]); // State to store length limit options
      const [timeframe, setTimeFrame] = useState([]); // State to store length limit options
      const [filteredJobs, setFilteredJobs] = useState([]); // State to store length limit options
      const [additionalEquipmentOptions, setAdditionalEquipmentOptions] = useState([]);
      const [radioValue, setRadioValue] = useState('');
      const [workExperience, setWorkExperience] = useState([]);
      const [workExperienceValue, setWorkExperienceValue] = useState('');
      const [portOfEntry, setPortOfEntry] = useState([]); // State to store length limit options
      const [typeOfWork, setTypeOfWork] = useState([]); // State to store length limit options


      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    
      const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
      };
    
      useEffect(() => {
        handleOpen(); // Open the modal automatically when the component mounts
      }, []);

      const clearFilters = () => {
        setDropdown5Value('');
        setDropdown1Value('');
        setDropdown2Value('');
        setDropdown3Value('');
        setDropdown4Value('');
        // Add additional clearing logic for other filters if needed
      };

      const [dropdown1Value, setDropdown1Value] = useState('');
      const [dropdown2Value, setDropdown2Value] = useState('');
      const [dropdown3Value, setDropdown3Value] = useState('');
      const [dropdown4Value, setDropdown4Value] = useState('');
      const [dropdown5Value, setDropdown5Value] = useState('');

      const handleDropdown1Change = (event) => {
        const selectedValue = event.target.value;
        const selectedField = event.target.name;
      
        // Filter the jobs based on the selected value and field
        let filteredJobs = [];
        if (selectedField === 'timeFrame') {
          filteredJobs = jobs.filter(job => job.timeFrame === selectedValue);
        } else if (selectedField === 'weightLimit') {
          filteredJobs = jobs.filter(job => job.weightLimit === selectedValue);
        } else if (selectedField === 'lengthLimit') {
          filteredJobs = jobs.filter(job => job.lengthLimit === selectedValue);
        }
        else if (selectedField === 'portofentry') {
          filteredJobs = jobs.filter(job => job.portOfEntry === selectedValue);
        }
        else if (selectedField === 'typeofwork') {
          filteredJobs = jobs.filter(job => job.typeOfWork === selectedValue);
        }
      
        // Update the state with the filtered jobs
        setFilteredJobs(filteredJobs);
        // Update the selected dropdown value
        if (selectedField === 'timeFrame') {
          setDropdown1Value(selectedValue);
        } else if (selectedField === 'weightLimit') {
          setDropdown2Value(selectedValue);
        } else if (selectedField === 'lengthLimit') {
          setDropdown3Value(selectedValue);
        }else if (selectedField === 'portofentry') {
          setDropdown4Value(selectedValue);
        }else if (selectedField === 'typeofwork') {
          setDropdown5Value(selectedValue);
        }
      };

      const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
      
        // Filter the jobs based on the selected value for additional equipment
        let filteredJobs = [];
        if (event.target.name === 'additional-equipment') {
          filteredJobs = jobs.filter(job => job.additionalEquipment === selectedValue);
        } else if (event.target.name === 'work-experience') {
          filteredJobs = jobs.filter(job => job.workExperience === selectedValue);
        }
      
        // Update the state with the filtered jobs
        setFilteredJobs(filteredJobs);
        // Update the selected radio button value
        if (event.target.name === 'additional-equipment') {
          setRadioValue(selectedValue);
        } else if (event.target.name === 'work-experience') {
          setWorkExperienceValue(selectedValue);
        }
      };
      
      
      
      useEffect(() => {
        // Fetch job data when the component mounts
        fetchJobs();
        handleOpen(); // Open the modal automatically when the component mounts
      }, []);
      useEffect(() => {
        setFilteredJobs(jobs);
      }, [jobs]);
      
      const [totalJobs, setTotalJobs] = useState(0); // State to store the total number of jobs

      const fetchJobs = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/job-post`);
          if (!response.ok) {
            throw new Error('Failed to fetch job data');
          }
          const data = await response.json();
      
          // Process job data
          setJobs(data);
          setTotalJobs(data.length);
      
          // Process dropdown options for length limit
          const lengthLimitOptions = data.map(job => ({
            value: job.lengthLimit,
            label: job.lengthLimit + '',
          }));
          setLengthLimitOptions(lengthLimitOptions);

          
          // Process dropdown options for length limit
          const weightLimitOptions = data.map(job => ({
            value: job.weightLimit,
            label: job.weightLimit + '',
          }));
          setWeightLimitOptions(weightLimitOptions);

          // Process dropdown options for length limit
          const typeOfWork = data.map(job => ({
            value: job.typeOfWork,
            label: job.typeOfWork + '',
          }));
          setTypeOfWork(typeOfWork);

           // Process dropdown options for length limit
           const portOfEntry = data.map(job => ({
            value: job.portOfEntry,
            label: job.portOfEntry + '',
          }));
          setPortOfEntry(portOfEntry);

           // Process dropdown options for timeframe
            const uniqueTimeframeValues = [...new Set(data.map(job => job.timeFrame))];
            const timeframeOptions = uniqueTimeframeValues.map(value => ({
              value: value,
              label: value + '',
            }));
            setTimeFrame(timeframeOptions);
      
              // Process radio button options for additional equipment
            const uniqueAdditionalEquipmentValues = [...new Set(data.map(job => job.additionalEquipment))];
            const additionalEquipmentOptions = uniqueAdditionalEquipmentValues.map(value => ({
              value: value,
              label: value + '',
            }));
            setAdditionalEquipmentOptions(additionalEquipmentOptions);


            const uniqueWorkExperince = [...new Set(data.map(job => job.workExperience))];
            const workExperience = uniqueWorkExperince.map(value => ({
              value: value,
              label: value + '',
            }));
            setWorkExperience(workExperience);



        } catch (error) {
          console.error('Error fetching job data:', error);
        }
      };
      

      const truncateText = (text, maxLength) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
          return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
      };
      useEffect(() => {
        const mode = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).mode : '';
        if (mode !== 'Bidding') {
          // Redirect to another page or handle accordingly
          window.location.href = '/dashboard'; // Redirect to home page
        } else {
          fetchJobs(); // Fetch jobs if mode is Bidding
          handleOpen(); // Open the modal automatically when the component mounts
        }
      }, []);

      const [range, setRange] = useState([20, 80]);

      const handleChange = (event, newValue) => {
        setRange(newValue);
      };


    return (
      <div style={{ display: 'flex' }}>
        {/* Sidebar */}
        <Sidebar />

        {/* Main Content */}
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <Header/>
          <br />
          <br />

          <Grid container spacing={2}>
            {/* Left side (70%) */}
            <Grid item xs={9}>
              <h2>{filteredJobs.length} jobs Availible</h2>
              {filteredJobs.map((job, index) => (

  <Link
  to={"/job-detail/"+job._id}
  style={{ textDecoration: 'none' }}
  >
  <Card variant="outlined" style={{ padding: '20px', boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.03)', borderRadius:'20px', marginBottom:'1rem' }}>
        <CardContent>
          <Grid container>
            {/* Left side (20%) */}
            <Grid item xs={1} style={{ display: 'flex',  alignItems:'flex-start', justifyContent:'center' }} >
              <BusinessCenterIcon fontSize="large" />
            </Grid>
            {/* Right side (80%) */}
            <Grid item xs={11} >
              <Typography variant="body1" style={{ fontSize: '20px', fontFamily: 'Poppins', color: '#000' }}>USA</Typography>
              <Typography variant="h6" style={{ color: '#000', fontFamily: 'Poppins', fontSize:'30px' }}>{job.title}</Typography>
              <Grid container spacing={2} style={{ width:'60%'}}>
                <Grid item xs={3} style={{ display: 'flex', flexDirection:'row' , alignItems:'flex-end', justifyContent:'center' }} >
                  <LocationOnIcon style={{ color: '#141414B2' }} />
                  <Typography variant="body2" style={{ color: '#141414B2', fontSize:'16px' }}>{truncateText(job.location, 1)}
</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', flexDirection:'row' , alignItems:'flex-end', justifyContent:'center' }} >
                  <CalendarMonthIcon style={{ color: '#141414B2' }} />
                  <Typography variant="body2" style={{ color: '#141414B2' ,fontSize:'16px' }}>{truncateText(job.typeOfWork, 1)}</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', flexDirection:'row' , alignItems:'flex-end', justifyContent:'center' }} >
                  <MonetizationOnIcon style={{ color: '#141414B2' }} />
                  <Typography variant="body2" style={{ color: '#141414B2', fontSize:'16px' }}>{job.budget}</Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', flexDirection:'row' , alignItems:'flex-end', justifyContent:'center' }} >
                  <EventIcon style={{ color: '#141414B2' }} />
                  <Typography variant="body2" style={{ color: '#141414B2', fontSize:'16px' }}>29 min ago</Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" style={{ color: '#141414B2', marginTop: '10px', fontSize: '18px', width: '100%' }}>
        {truncateText(job.briefDescription, 30)}
      </Typography>
                </Grid>
          </Grid>
        </CardContent>
              </Card></Link>
                            ))}

            </Grid>
            {/* Right side (30%) */}
            <Grid item xs={3} style={{ display: 'flex',  alignItems: 'center', flexDirection: 'column',  }}>
            <Button
                variant="outlined"
                startIcon={<FilterListIcon />}
                style={{ borderRadius: 0, backgroundColor: 'white', border: '1px solid grey', fontSize: '1.2rem', color: 'black' }}
                onClick={toggleDrawer} // Open the drawer when the filter button is clicked
              >
                Filter
              </Button>
              <br/>
              <br/>
              <Card variant="outlined" style={{ padding: '20px', textAlign: 'center', boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.03)', borderRadius:'20px' }}>
                <CardContent>
                  <Typography variant="h5" style={{ fontFamily: 'Poppins', color: 'black', fontWeight:'700' }}>Email me for jobs</Typography>
                  <Typography variant="body1" style={{ fontFamily: 'Poppins', color: '#141414CC', width:'100%' }}>Ut esse eiusmod aute. Sit enim labore dolore. Aute ea fugiat commodo ea foes.</Typography>
                  <TextField label="Email" fullWidth style={{ marginBottom: '10px' }} />
                  <Button variant="contained" fullWidth style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px', padding: '10px' }}>
                    Subscribe
                  </Button>
                </CardContent>
              </Card>
              <br/>
              <Card variant="outlined" style={{ padding: '20px', textAlign: 'center', boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.03)', borderRadius:'20px' }}>
                <CardContent>
                  <Typography variant="h5" style={{ fontFamily: 'Poppins', color: 'black', fontWeight:'700' }}>Get noticed faster</Typography>
                  <Typography variant="body1" style={{ fontFamily: 'Poppins', color: '#141414CC', width:'100%' }}>Quis eiusmod deserunt cillum laboris magna cupidatat esse labore irure quis cupidatat in.</Typography>
                  <Button variant="contained" fullWidth style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px', padding: '10px', marginTop: '10px' }}>
                    Upload your resume
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Drawer 
    anchor="left" 
    open={drawerOpen} 
    onClose={toggleDrawer} 
    PaperProps={{ 
      style: { 
        width: 700, // Set the width of the drawer
        borderTopRightRadius: 50, // Set borderTopRightRadius to 50px
        borderBottomRightRadius: 50, // Set borderBottomRightRadius to 50px
      } 
    }}
  >
    {/* Content of the drawer */}
    <div style={{ padding: '30px' }}>
      {/* First Grid: Radio Button Fields */}
      <Grid container spacing={2} >
        <Grid item xs={6} style={{ display:'flex', flexDirection:'column'}}>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontFamily: 'Poppins', fontSize:'22px', color:'black' }}>Location</FormLabel>
            <RadioGroup aria-label="field1" name="field1">
              <FormControlLabel value="option1"  control={<Radio />} label="Near me" />
              <FormControlLabel value="option2"  control={<Radio />} label="Remote Job" />
              <FormControlLabel value="option3" control={<Radio />} label="Exact Location" />
              <FormControlLabel value="option4" control={<Radio />} label="within 15 km" />
              <FormControlLabel value="option5" control={<Radio />} label="within 30 km" />
              <FormControlLabel value="option6" control={<Radio />} label="within 50 km" />

            </RadioGroup>
          </FormControl>
          <br/>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ fontFamily: 'Poppins', fontSize:'22px', color:'black' }}>Ending in</FormLabel>
            <RadioGroup aria-label="field1" name="field1">
              <FormControlLabel value="option2" control={<Radio />} label="24 hours" />
              <FormControlLabel value="option3" control={<Radio />} label="3 Days" />
              <FormControlLabel value="option4" control={<Radio />} label="7 Days" />

            </RadioGroup>
          </FormControl>
          <br/>
        </Grid>
        <Grid item xs={6} style={{ display:'flex', flexDirection:'column', padding:'30px'}}>
        <FormControl fullWidth>
        <FormLabel component="legend" style={{ fontFamily: 'Poppins', fontSize:'22px', color:'black' }}>Port of Entry </FormLabel>
        <Select
                                labelId="portof-entry-select-label"
                                name='portofentry'
                                id="portof-entry-select"
                                value={dropdown4Value}
                                onChange={handleDropdown1Change}
                            >
                                {portOfEntry.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
          </FormControl>
          <br/>
        <FormControl fullWidth>
        <FormLabel component="legend" style={{ fontFamily: 'Poppins', fontSize:'22px', color:'black' }}>Posted Within</FormLabel>
        <Select
                                labelId="length-limit-select-label"
                                name='timeFrame'
                                id="length-limit-select"
                                value={dropdown1Value}
                                onChange={handleDropdown1Change}
                            >
                                {timeframe.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
          </FormControl>
          <br/>
          <FormControl fullWidth>
        <FormLabel component="legend" style={{ fontFamily: 'Poppins', fontSize:'22px', color:'black' }}>Partner Government Agencies</FormLabel>
        <Select
                                labelId="length-limit-select-label"
                                name='typeofwork'
                                id="length-limit-select"
                                value={dropdown5Value}
                                onChange={handleDropdown1Change}
                            >
                                {typeOfWork.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
          </FormControl>
          <br/>
          <br/>
          <Button variant="outlined" color="primary" onClick={clearFilters}>Clear Filter</Button>

          <br/>
      
        </Grid>
      </Grid>
      <br />
      {/* Second Grid: Dropdowns */}
    
    </div>
  </Drawer>

          <br />
          <br />

          {/* Cards */}
          
          <br />
          <br />
          {/* Modal */}
          
        </div>
      </div>
    );
  };

  export default FindWork;
