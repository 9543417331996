import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import ProgressCard from './FourCards';
import MyTable from './Tables';
import { Button } from '@mui/material';
import './posting.css'
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleOpen(); // Open the modal automatically when the component mounts
  }, []);
  useEffect(() => {
    const mode = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).mode : '';
    if (mode !== 'Posting') {
      // Redirect to another page or handle accordingly
      window.location.href = '/find-work'; // Redirect to home page
    } else {
      handleOpen(); // Open the modal automatically when the component mounts
    }
  }, []);
  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Header/>
        {/* <h1>Welcome to Dashboard</h1> */}
        <br />
        <br />
        <ProgressCard/>
        <br />
        <MyTable/>
        {/* Modal */}
        {/* <Modal
          open={open}
          onClose={handleClose}
        >
          <Box
            className='posting-modal'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              boxShadow: 'none', // Remove any shadow or border
              p: 4,
              border: 'none !important', // Add !important


            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                border: '1px solid black',
                borderRadius: '100px',
                color:'black',
                padding:'3px'
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <h2 id="modal-modal-title">Would you like to post a new entry</h2>
              <Link to="/job-post">
                <Button variant="contained" className='modal-btn'>Post</Button>
              </Link>
            </div>
          </Box>
        </Modal> */}
      </div>
    </div>
  );
};

export default Dashboard;
