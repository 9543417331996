import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './posting.css';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import { Link } from 'react-router-dom';

const data = [
  {
    avatarSrc: 'https://example.com/avatar1.png',
    title: 'Job Title 1',
    dateOfEntry: '2024-03-15',
    timeFrame: '2 days',
    budget: '$100',
    _id: '1'
  },
  {
    avatarSrc: 'https://example.com/avatar2.png',
    title: 'Job Title 2',
    dateOfEntry: '2024-03-16',
    timeFrame: '3 days',
    budget: '$150',
    _id: '2'
  },
  // Add more data objects as needed
];

const LiveAuctions = () => {
  const [jobs, setJobs] = useState([]); // State to store job data

  useEffect(() => {
    // Fetch job data when the component mounts
    fetchJobs();
  }, []);
  
  const fetchJobs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/job-post`); // Change the URL to match your API endpoint
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date as per the user's locale
  };
  

  const [counter, setCounter] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    // Calculate deadline date 2 days from now
    const deadlineDate = new Date();
    deadlineDate.setDate(deadlineDate.getDate() + 2);

    const intervalId = setInterval(() => {
      // Calculate time difference between deadline and current date
      const timeDifference = deadlineDate - new Date();
      if (timeDifference < 0) {
        // Timer has expired
        clearInterval(intervalId);
        setCounter({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        // Calculate remaining time
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setCounter({ days, hours, minutes, seconds });
      }
    }, 1000);
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <>  
     <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Header />
        <br />
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ pb:5 , pr: 5, pl:5 }}>
          <h2 className='tables-heading'>Live Auctions</h2>
          <div className='dash-table-div'>
            <Table className='dash-table'>
              <TableBody className='dash-table-body'>
                <TableRow>
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Project ID</TableCell>
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Title</TableCell>

                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Views</TableCell>
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Total Bids</TableCell>

                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Unique Bidder</TableCell>
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>High Bid</TableCell>
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Low Bid</TableCell>

                  {/* <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Date of Entry</TableCell> */}
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Time Remaining</TableCell>
                  {/* <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Budget</TableCell> */}
                  <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>Action</TableCell>
                </TableRow>
                {jobs.map((job, index) => (
                  <TableRow key={index} className='dash-table-row'>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>ID</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>{job.title}</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>5</TableCell>

                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>12</TableCell>

                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>20</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>$100</TableCell>
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>$20</TableCell>

                    {/* <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: '#737791', fontSize: '14px' }}>{formatDate(job.dateOfEntry)}</TableCell> */}
                    <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: '#737791', fontSize: '14px' }}><span style={{ fontFamily: 'Poppins', fontSize: '18px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{counter.days} day : {counter.hours} hours : {counter.minutes} minutes : {counter.seconds} seconds</span>
</TableCell>
                    {/* <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '25px' }}>{job.budget}</TableCell> */}
                    <TableCell style={{ textAlign: 'center' }}>
                      <Link to={"/live-auctions/"+job._id} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" className='danger-btn'>View Details</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid> 
      </div>
    </div> 
    </>
  );
};

export default LiveAuctions;
