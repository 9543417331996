import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import './component.css';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState('');
  const [mode, setMode] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    console.log('Stored user:', storedUser);
  
    if (storedUser) {
      const { result, mode } = JSON.parse(storedUser);
      if (result && result.username) {
        const { username } = result;
        console.log('Username:', username);
        console.log('Mode:', mode);
        setUsername(username);
        setMode(mode);
      } else {
        console.error('Error: Username not found in stored user data');
      }
    } else {
      console.log('No user data found in localStorage');
    }
  }, []);
  
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    console.log('Stored user:', storedUser);
  
    if (storedUser) {
      const { username, mode } = JSON.parse(storedUser);
      if (username) {
        console.log('Username:', username);
        console.log('Mode:', mode);
        setUsername(username);
        setMode(mode);
      } else {
        console.error('Error: Username not found in stored user data');
      }
    } else {
      console.log('No user data found in localStorage');
    }
  }, []);
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <AppBar position="static" className='header-d'>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <InputBase
            placeholder="Search here…"
            inputProps={{ 'aria-label': 'search' }}
            fullWidth
            startAdornment={<SearchIcon style={{ color: 'grey', marginRight: '8px' }} />}
            className='search-bg'
            style={{ background: '#F9FAFB', borderRadius: '10px', border: 'none', paddingLeft: '15px' }}
          />
        </div>
        <IconButton color="inherit" style={{ borderRadius: '20px', background: 'black', marginLeft: '8px' }}>
          <NotificationsIcon style={{ color: 'white', borderRadius: '10px', padding: '5px' }} />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleMenuOpen}
          aria-controls="user-menu"
          aria-haspopup="true"
          style={{ marginLeft: '8px' }}
        >
          <Avatar alt={username} style={{ marginRight: '8px' }} /> 
          <div style={{ fontFamily: 'Poppins', display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '16px', color: 'black' }}>{username}</span>
            <span style={{ fontSize: '14px', color: '#737791' }}>
  {mode === 'Posting' ? 'Post' : mode === 'Bidding' ? 'Bid' : mode}
</span>
          </div>
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            <Link onClick={handleSignOut} style={{ textDecoration: 'none', color: 'inherit' }}>
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
