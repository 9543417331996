import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import { Avatar, Typography, Divider, TextField, Grid } from '@mui/material';
import './posting.css'
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';

const Chat = () => {
  // Dummy chat data
  const dummyChats = [
    { id: 1, name: 'John Doe', message: 'Hey there!', timestamp: '10:00 AM' },
    { id: 2, name: 'Jane Smith', message: 'How are you?', timestamp: '11:30 AM' },
    { id: 3, name: 'Alice', message: 'What are you doing?', timestamp: 'Yesterday' },
    { id: 4, name: 'Bob', message: 'Let\'s meet up!', timestamp: '2 days ago' },
    { id: 5, name: 'Charlie', message: 'See you soon!', timestamp: 'Last week' }
  ];

  const [selectedChat, setSelectedChat] = useState(dummyChats[0]); // Set first chat as selected by default

  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Header />
        <br />
        <Grid container spacing={2}>
          {/* Chat List */}
          <Grid item xs={4}>
            <div className="sidebar">
              {/* Search field */}
              <div className='chat-side-head'>
                  <h5>All Messages</h5>
              </div>
              <div className='chat-search-box'>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    style: {
                      backgroundColor: '#F8F9FD',
                      borderRadius: '15px',
                    },
                  }}
                />
              </div>
              {/* Chat items */}
              {dummyChats.map(chat => (
                <div key={chat.id} className="chat-item" onClick={() => setSelectedChat(chat)}>
                  <div className="info">
                    <Avatar className="avatar" />
                    <div>
                      <Typography className="name" variant="subtitle1">{chat.name}</Typography>
                      <Typography className="message" variant="body2">{chat.message}</Typography>
                      <Typography className="time" variant="body2">{chat.timestamp}</Typography>
                    </div>
                    <StarIcon className="star" />
                  </div>
                </div>
              ))}
            </div>
          </Grid>

          {/* Selected Chat */}
          <Grid item xs={8}>
            {selectedChat && (
              <div className='message-area' style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
                {/* Chat header with avatar */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <Avatar src={selectedChat.avatar} style={{ marginRight: '10px' }} />
                  <Typography variant="h5" className='indivisual-chat-head'>{selectedChat.name}</Typography>
                </div>
                <Divider style={{ marginBottom: '10px' }} />
                
                {/* Chat messages */}
                <div className='messages-input' >
                  <div style={{ marginBottom: '10px' }}>
                    <div className='coming-message-box'>
                      <Typography variant="body2">{selectedChat.name}: Hi, I'm doing great!</Typography>
                    </div>
                  </div>
                  <div style={{ marginBottom: '10px', textAlign: 'right' }}>
                    <div className='my-message-box'>
                      <Typography variant="body2"><strong>You:</strong> Hi, how are you?</Typography>
                    </div>
                  </div>
                </div>

                {/* Message input field */}
                <div className='message-sending-area' style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <EmojiEmotionsIcon style={{ marginRight: '10px' }} />
                  <TextField label="Type a message" fullWidth />
                  <MicIcon style={{ marginLeft: '10px', marginRight: '10px' }} />
                  <SendIcon />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;
