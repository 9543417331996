import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '../../assets/avatar.png'
import { Button, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import invoice from '../../assets/invoice.jpg'
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead'; // Import TableHead
import TableSortLabel from '@mui/material/TableSortLabel'; // Import TableSortLabel
import Doc1 from '../../assets/doc1.png';
import Doc2 from '../../assets/doc2.jpg';
import Doc3 from '../../assets/doc3.png';
import Doc4 from '../../assets/doc4.jpg';
import { Slider } from '@mui/material';
import {  TextField, Select, MenuItem, FormControl } from '@mui/material';


const JobDetail = () => {
  const [jobs, setJobs] = useState([]); // State to store job data
  const params = useParams();
  
  const [title, setTitle] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const [dateOfEntry, setDateOfEntry] = useState('');
  const [portOfEntry, setPortOfEntry] = useState('');
  const [merchandise, setMerchandise] = useState('');
  const [transportMethod, setTransportMethod] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [lengthLimit, setLengthLimit] = useState('');
  const [weightLimit, setWeightLimit] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [additionalEquipment, setAdditionalEquipment] = useState('');
  const [location, setLocation] = useState('');
  const [budget, setBudget] = useState('');
  const [typeOfWork, setTypeOfWork] = useState('');



  const getTeamDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/job-posts/${params.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch job details');
      }
      const result = await response.json();
      
      setTitle(result.title);
      setBriefDescription(result.briefDescription);
      setDateOfEntry(new Date(result.dateOfEntry).toLocaleDateString()); // Convert date to a readable format
      setPortOfEntry(result.portOfEntry);
      setMerchandise(result.merchandise);
      setTransportMethod(result.transportMethod);
      setTimeFrame(result.timeFrame);
      setLengthLimit(result.lengthLimit);
      setWeightLimit(result.weightLimit);
      setWorkExperience(result.workExperience);
      setAdditionalEquipment(result.additionalEquipment);
      setLocation(result.location);
      setBudget(result.budget);
      setTypeOfWork(result.typeOfWork);




    
 
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  useEffect(() => {
    getTeamDetails();
  }, [params.id]);

  useEffect(() => {
    // Fetch job data when the component mounts
    fetchJobs();
  }, []);
  
  const [counter, setCounter] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    // Calculate deadline date 2 days from now
    const deadlineDate = new Date();
    deadlineDate.setDate(deadlineDate.getDate() + 2);

    const intervalId = setInterval(() => {
      // Calculate time difference between deadline and current date
      const timeDifference = deadlineDate - new Date();
      if (timeDifference < 0) {
        // Timer has expired
        clearInterval(intervalId);
        setCounter({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        // Calculate remaining time
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setCounter({ days, hours, minutes, seconds });
      }
    }, 1000);
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return text;
  };
  const fetchJobs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/job-post`); // Change the URL to match your API endpoint
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date as per the user's locale
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const docImages = [Doc1, Doc2, Doc3, Doc4];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === docImages.length - 1 ? 0 : prevSlide + 1));
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? docImages.length - 1 : prevSlide - 1));
  };

  const handleChangeSlide = (event, newValue) => {
    setCurrentSlide(newValue);
  };

  const [openModal, setOpenModal] = useState(false); // State to control the visibility of the modal
  const [bidAmount, setBidAmount] = useState(''); // State to store the bid amount
  const [bidSubmitted, setBidSubmitted] = useState(false); // State to track whether bid is submitted

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitBid = () => {
    // You can perform any necessary logic here, such as sending the bid to the server
    // For demonstration, we'll just simulate a delay before showing the success message
    setTimeout(() => {
      setBidSubmitted(true);
    }, 2000); // 2 seconds delay
  };

  const handleResetBid = () => {
    setBidSubmitted(false);
    setBidAmount('');
    handleCloseModal();
  };
  return (
    <>  
     <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Header />
        <br />



        <Grid container justifyContent="center" alignItems="center" sx={{ pb:5 }}>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card className='auction-detail-card' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent>
            <Button onClick={handleOpen}>
              <img src={invoice} width={'300px'} height={'400px'} alt="Invoice" />
            </Button>
            </CardContent>
            </Card>
            <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: '80%',
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <img src={invoice} alt="Invoice" width={'500px'} height={'800px'} /> */}
        <Slider
          value={currentSlide}
          min={0}
          max={docImages.length - 1}
          onChange={handleChangeSlide}
          aria-labelledby="slider"
          sx={{ width: '80%' }}
        />
        {/* Render the current image based on currentSlide */}
        <img src={docImages[currentSlide]} alt={`Document ${currentSlide + 1}`} width={'500px'} height={'600px'} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}>
          <Button onClick={handlePrevSlide}>Previous</Button>
          <Button onClick={handleNextSlide}>Next</Button>
        </Box>
      </Box>
    </Modal>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card className='auction-detail-card' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent>

            <Grid item xs={12}>
                    <Typography variant="h3" style={{ fontFamily: 'Poppins', fontSize: '20px', color: 'black', width: '100%', margin: '0' }}>
                    {title}
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#141414B2', width: '100%' }}>
                    {truncateText(briefDescription, 30)}
                    </Typography>
               
                    <Typography variant="h5" style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'black', width: '100%', margin: '0', fontWeight: '500' }}>
                    Port of Entry: <span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{portOfEntry}</span>
                    </Typography>
                    <Typography variant="h5" style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'black', width: '100%', margin: '0', fontWeight: '500' }}>
                    Commodity : <span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{merchandise}</span>
                    </Typography>
                    <Typography variant="h5" style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'black', width: '100%', margin: '0', fontWeight: '500' }}>
                    Transport: <span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{transportMethod}</span>
                    </Typography>
                    <Typography variant="h5" style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'black', width: '100%', margin: '0', fontWeight: '500' }}>
                    Estimated Value: <span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{budget}</span>
                    </Typography>
                    <Typography variant="h5" style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'black', width: '100%', margin: '0', fontWeight: '500' }}>
                    Partner Government Agencies: <span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{typeOfWork}</span>
                    </Typography>
                </Grid>
            </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card className='auction-detail-card' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent>

                    <h5 className='progress-circular-heading'>
                    Live auction <br/><span style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'black', width: '100%', margin: '0', fontWeight: '400' }}>{counter.days} day : {counter.hours} hours : {counter.minutes} minutes : {counter.seconds} seconds</span>
                    </h5>
                    
                </CardContent>
                </Card>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={'/messages'}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '10px',
                fontFamily: 'Poppins',
                fontSize: '20px',
                width: 'auto',
                textAlign: 'right',
                marginRight:'20px'
              }}
            >
              Message
            </Button>
            </Link>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '10px',
                fontFamily: 'Poppins',
                fontSize: '20px',
                width: 'auto',
                textAlign: 'right',
              }}
              onClick={handleOpenModal} // Open the modal when clicked

            >
              Bid
            </Button>
            <Modal
       open={openModal}
       onClose={handleCloseModal}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
       sx={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
       }}
     >
       <Box
         sx={{
           bgcolor: 'background.paper',
           boxShadow: 24,
           p: 4,
           width: '50%',
           maxWidth: '500px',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           borderRadius: '20px'
         }}
       >
         {bidSubmitted ? (
           <>
             <h3 style={{ fontFamily: 'Poppins', fontSize: '20x', color: 'black', width: '100%', margin: '0', fontWeight: '500' ,textAlign:'center' }}>
               Bid Submitted Successfully
               </h3>
               <Button
               variant="contained"
               onClick={handleResetBid}
               sx={{ backgroundColor: 'black', color: 'white', borderRadius: '15px', mt: 2 }} // Margin top
             >
               Close
             </Button>
           </>
         ) : (
           <>
             <TextField
               label="How much would you like to bid?"
               fullWidth
               variant="outlined"
               style={{ marginBottom: '20px', borderRadius: '15px' }}
               name="bidAmount"
               value={bidAmount}
               onChange={(e) => setBidAmount(e.target.value)}
             />
             <Button
               variant="contained"
               onClick={handleSubmitBid}
               sx={{ backgroundColor: 'black', color: 'white', borderRadius: '15px', width: '100%', mb: 1 }} // Full width and margin bottom
             >
               Submit
             </Button>
           </>
         )}
       </Box>
     </Modal>
          </Grid>
        </Grid>
        

    </div>
    </div> 
    </>
  );
};

export default JobDetail;
