import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import './index.css'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Globe from '../../assets/globe.png'
import Whitelogo from '../../assets/logow.png'

const Splash = () => {
    const history = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            history('/login');
        }, 5000);

        return () => clearTimeout(timer);
    }, [history]);

    return (
        <div className="splash-bg">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 2 , sm:1 } }}>
                    <img src={Globe} alt="Globe" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', textAlign: 'center', pl: { xs: 4 }, pr: { xs: 4 }, pt: { xs: 4 }, pb: { xs: 4 }, order: { xs: 1 , sm:2} }}>
                    <img src={Whitelogo} alt="White Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />    
                    {/* <p>Customs brokers ensure smooth passage of goods through customs, handling complex paperwork and regulations</p> */}
                </Grid>
            </Grid>
        </div>
    )
}

export default Splash;
