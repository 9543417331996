import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './posting.css';
import Map from '../../assets/map.jpg'
import Progress from '../../assets/progress-bg.png'
import Avatar from '../../assets/avatar.png'
import { PieChart } from '@mui/x-charts/PieChart';

const data = [
  { value: 10, label: 'Live' },
  { value: 15, label: 'Won' },
  { value: 20, label: 'Finished' },
];
const size = {
  width: 900,
  height: 500,
};
const MyTable = () => {
  const [jobs, setJobs] = useState([]); // State to store job data

  useEffect(() => {
    // Fetch job data when the component mounts
    fetchJobs();
  }, []);
  
  const fetchJobs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/job-post`); // Change the URL to match your API endpoint
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date as per the user's locale
  };
  return (
    <>  
       <Grid container justifyContent="center" alignItems="center" sx={{ pb:5 ,pr:5,pl:5 }}>
       <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

<div className='dash-table-div'>
<h2 className='tables-heading'>Bidding Activity</h2>

  <Table className='dash-table'>
    <TableBody className='dash-table-body'>
    {jobs.map((job, index) => (
        <TableRow key={index} className='dash-table-row'>
          <TableCell style={{ textAlign: 'center' }}>
            {/* <Avatar style={{ margin: 'auto', display: 'block' }}  src={job.avatarSrc} /> */}
            <img src={Avatar}/>
          </TableCell>
          <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>{job.title}</TableCell>
          <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: 'black', fontSize: '14px' }}>$250</TableCell>

          <TableCell style={{ textAlign: 'center', fontFamily: 'Poppins', color: '#737791', fontSize: '14px' }}>      {formatDate(job.dateOfEntry)}
</TableCell>
         
        </TableRow>
      ))}
    </TableBody>
  </Table>
</div>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PieChart
          colors={['#FF6961', '#AEC6CF', '#77dd77']} // Use palette

            series={[
              {
                arcLabel: (item) => `${item.label} (${item.value})`,
                data,
              },
            ]}
            label={{
              fill: 'white', // Set text color to white
              fontWeight: 'bold',
            }}
            {...size}
          />
            
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Map} width={'550px'}/>
          </Grid>
        
        </Grid>

    </>
  );
};

export default MyTable;
